import React, { useState, useEffect } from 'react';
import api from '../services/api'; // Ensure you have your API setup
import '../styles.css';

function Products() {
  const [products, setProducts] = useState([]);
  const [name, setName] = useState('');
  const [color, setColor] = useState('');
  const [qbProdId, setQbProdId] = useState('');
  const [qbProducts, setQbProducts] = useState([]); // For storing QuickBooks products for the dropdown
  const [glassBottle, setGlassBottle] = useState(false);
  const [masterGlassBottle, setMasterGlassBottle] = useState(false);
  const [sequenceNumber, setSequenceNumber] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track if form is submitting

  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();
    fetchQbProducts(); // Fetch QuickBooks products for the dropdown
  }, []);

  const fetchProducts = () => {
    setLoading(true);
    api.get('/api/products/')
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('There was an error fetching the products.');
        setLoading(false);
      });
  };

  const fetchQbProducts = () => {
    api.get('/api/import-products/')
      .then((response) => {
        setQbProducts(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the QuickBooks products.', error);
      });
  };

  // Handle add/edit form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true
    const productData = {
      name,
      color,
      qb_prod_id: qbProdId,
      glass_bottle: glassBottle, // Include glass_bottle in submission data
      master_glass_bottle: masterGlassBottle,
      sequence_number: sequenceNumber
    };

    if (selectedProduct) {
      // Editing an existing product
      api.put(`/api/products/${selectedProduct.id}/`, productData)
        .then((response) => {
          setProducts(products.map((product) => 
            product.id === selectedProduct.id ? response.data : product
          ));
          resetForm();
        })
        .catch((error) => {
          console.error('There was an error updating the product!', error);
        })
        .finally(() => {
          setIsSubmitting(false); // Reset the submitting state after completion
        });
    } else {
      // Adding a new product
      api.post('/api/products/', productData)
        .then((response) => {
          setProducts([...products, response.data]);
          resetForm();
        })
        .catch((error) => {
          console.error('There was an error adding the product!', error);
        })
        .finally(() => {
          setIsSubmitting(false); // Reset the submitting state after completion
        });
    }
  };

  const handleDelete = (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      api.delete(`/api/products/${productId}/`)
        .then(() => {
          setProducts(products.filter((product) => product.id !== productId));
        })
        .catch((error) => {
          console.error('There was an error deleting the product!', error);
        });
    }
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setName(product.name);
    setColor(product.color);
    setQbProdId(product.qb_prod_id || '');
    setGlassBottle(product.glass_bottle || false);
    setMasterGlassBottle(product.master_glass_bottle || false);
    setSequenceNumber(product.sequence_number || 0);

    // Scroll to the top of the page when Edit is clicked
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const resetForm = () => {
    setName('');
    setColor('');
    setQbProdId('');
    setGlassBottle(false);
    setMasterGlassBottle(false);
    setSequenceNumber(0);
    setSelectedProduct(null);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Products</h2>

      {/* Form for adding/editing a product */}
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
            disabled={isSubmitting} // Disable while submitting
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Sequence Number:</label>
          <input
            type="number"
            value={sequenceNumber}
            onChange={(e) => setSequenceNumber(parseInt(e.target.value) || 0)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
            disabled={isSubmitting} // Disable while submitting
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Color:</label>
          <select
            value={color}
            onChange={(e) => setColor(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
            disabled={isSubmitting} // Disable while submitting
          >
            <option value="" disabled>Select color...</option>
            <option value="#FFFFFF" style={{ backgroundColor: '#FFFFFF', color: '#000' }}>White</option>
            <option value="#E57373" style={{ backgroundColor: '#E57373', color: '#000' }}>Red</option>
            <option value="#F8BBD0" style={{ backgroundColor: '#F8BBD0', color: '#000' }}>Pink</option>
            <option value="#ffeb80" style={{ backgroundColor: '#ffeb80', color: '#000' }}>Yellow</option>
            <option value="#2196F3" style={{ backgroundColor: '#2196F3', color: '#FFF' }}>Dark Blue</option>
            <option value="#BBDEFB" style={{ backgroundColor: '#BBDEFB', color: '#000' }}>Light Blue</option>
            <option value="#8D6E63" style={{ backgroundColor: '#8D6E63', color: '#FFF' }}>Dark Brown</option>
            <option value="#BCAAA4" style={{ backgroundColor: '#BCAAA4', color: '#000' }}>Light Brown</option>
            <option value="#90A4AE" style={{ backgroundColor: '#90A4AE', color: '#000' }}>Grey</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">QuickBooks Product ID:</label>
          <select
            value={qbProdId}
            onChange={(e) => setQbProdId(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            disabled={isSubmitting} // Disable while submitting
          >
            <option value="" disabled>Select QuickBooks Product...</option>
            {qbProducts.map((qbProduct) => (
              <option key={qbProduct.id} value={qbProduct.id}>
                {qbProduct.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            id="glassBottle"
            checked={glassBottle}
            onChange={(e) => setGlassBottle(e.target.checked)} // Update state based on checkbox
            className="form-checkbox h-5 w-5 text-blue-600"
            disabled={isSubmitting} // Disable while submitting
          />
          <label
            htmlFor="glassBottle"
            className="ml-2 text-gray-700"
            style={{ marginTop: '10px' }} // Adjust margin-top for better alignment
          >
            Is this product a glass bottle?
          </label>
        </div>
        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            id="masterGlassBottle"
            checked={masterGlassBottle}
            onChange={(e) => setMasterGlassBottle(e.target.checked)}  // Set the master bottle state
            className="form-checkbox h-5 w-5 text-blue-600"
            disabled={isSubmitting}  // Disable while submitting
          />
          <label
            htmlFor="masterGlassBottle"
            className="ml-2 text-gray-700"
            style={{ marginTop: '10px' }}
          >
            Master Glass Bottle (Only one can be selected)
          </label>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
            disabled={isSubmitting} // Disable while submitting
          >
            {isSubmitting ? (
              <>
                <span className="spinner"></span> {/* Assuming spinner class exists in your styles.css */}
                Saving...
              </>
            ) : (
              selectedProduct ? 'Update Product' : 'Add Product'
            )}
          </button>
          {selectedProduct && (
            <button
              type="button"
              onClick={resetForm}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              disabled={isSubmitting} // Disable while submitting
            >
              Cancel
            </button>
          )}
        </div>
      </form>

      {/* Product list */}
      <div className="bg-white shadow-md rounded p-4">
        <h3 className="text-xl font-bold mb-4">Existing Products</h3>
        <ul className="space-y-2">
          {products.map((product) => (
            <li
              key={product.id}
              className="flex justify-between items-center p-1 rounded"
              style={{ backgroundColor: product.color }}
            >
              <span>{product.name}</span>
              <div>
                <button
                  onClick={() => handleEdit(product)}
                  className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(product.id)}
                  className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Products;
