import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';  // Import react-modal
import api from '../services/api';  
import '../styles.css';
import { parseBlobError } from '../utils/parseBlobError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faFileInvoiceDollar, faReceipt } from '@fortawesome/free-solid-svg-icons'

Modal.setAppElement('#root');

const EditDelivery = () => {
  const { deliveryId } = useParams();
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [productInventories, setProductInventories] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);  // Track PDF generation
  const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);  // Track modal visibility
  const [invoiceDate, setInvoiceDate] = useState('');  // Store the invoice date
  const [showCreditMemoModal, setShowCreditMemoModal] = useState(false);  // Track modal visibility for credit memo
  const [creditMemoDate, setCreditMemoDate] = useState('');
  const [notification, setNotification] = useState('');  // State to show feedback notifications
  const [isError, setIsError] = useState(false);  // Track if error occurred
  const [isDataFetched, setIsDataFetched] = useState(false); 
  const [previousDelivery, setPreviousDelivery] = useState(null);
  const [bottlesPickedUp, setBottlesPickedUp] = useState(0);
  const [totalBottlesDelivered, setTotalBottlesDelivered] = useState(0);
  const hasGlassBottles = products.some(product => product.glass_bottle);
  const [outdatedGlassBottles, setOutdatedGlassBottles] = useState(0);

  // Fetch combined delivery data
  useEffect(() => {
    setIsLoading(true);

    api.get(`/api/combined-delivery-data/${deliveryId}/`)
      .then(response => {
        const data = response.data;

        // Set stores
        setStores(data.stores);

        // Set delivery data
        const delivery = data.delivery;
        setSelectedStore(delivery.store);
        setDeliveryDate(delivery.date);

        // Set glass bottle info here
        setBottlesPickedUp(delivery.bottles_picked_up || 0);
        setTotalBottlesDelivered(delivery.bottles_delivered || 0);
        setOutdatedGlassBottles(delivery.outdated_glass_bottles || 0);

        // Set delivery products
        const inventories = {};
        data.delivery_products.forEach(product => {
          inventories[product.product] = {
            id: product.id,
            previous_inventory: product.previous_inventory,
            current_inventory: product.current_inventory,
            outdated_inventory: product.outdated_inventory,
            eight_week_average: product.eight_week_average,
            quantity: product.quantity,
            sold: product.sold,
            order: product.order,
            orderManuallySet: product.order_manually_set,
          };
        });
        setProductInventories(inventories);
        setPreviousDelivery(data.previous_delivery);

        // Set products based on the selected store
        const currentStore = data.stores.find(store => store.id === delivery.store);
        if (currentStore) {
          const assignedProducts = currentStore.assigned_products;  // Full product details are already included
          setProducts(assignedProducts);
        } else {
          setProducts([]);
        }
        
        setIsDataFetched(true);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the combined delivery data!', error);
        setIsLoading(false); 
      });
  }, [deliveryId]);

  const handleProductQuantityChange = (productId, field, value) => {
    if (!isDataFetched) return; // Prevent automatic patching on load
  
    // Ensure productInventories[productId] is initialized
    if (!productInventories[productId]) {
      console.error(`Product inventory for product ID ${productId} is not initialized.`);
      return;
    }

    const parsedValue = value === '' ? null : (isNaN(parseFloat(value)) ? value : parseFloat(value));

    setProductInventories(prevInventories => ({
      ...prevInventories,
      [productId]: {
        ...prevInventories[productId],
        [field]: parsedValue,
        ...(field === 'order' && { orderManuallySet: true })  // Set the flag if the order field is changed
      }
    }));
  
    const deliveryProductId = productInventories[productId].id;  // Safely access the delivery product ID
  
    if (deliveryProductId) {
      // Update the database immediately when a field changes
      api.patch(`/api/delivery-products/${deliveryProductId}/`, {
        [field]: parsedValue,
        ...(field === 'order' && { order: value, order_manually_set: true })  // Include order value and flag if order is changed
      })
        .catch(error => {
          console.error('There was an error updating the product inventory!', error);
        });
    } else {
      console.error('Delivery product ID is undefined for product ID:', productId);
    }
  };  

  useEffect(() => {
    if (!isDataFetched) return;  // Prevent running this effect on initial load

    const calculateAndSaveInventory = () => {
      const updatedInventories = { ...productInventories };
      let hasChanges = false;
  
      Object.keys(productInventories).forEach(productId => {
        const inventory = productInventories[productId];
        const sold = (inventory.previous_inventory || 0) - (inventory.current_inventory || 0);
        const order = inventory.orderManuallySet
          ? inventory.order
          : inventory.eight_week_average === 0
            ? sold + (inventory.outdated_inventory || 0)
            : Math.round((sold + (inventory.eight_week_average || 0)) / 2 + (inventory.outdated_inventory || 0));
  
        // Only update if the order has changed
        if (inventory.sold !== sold || (!inventory.orderManuallySet && inventory.order !== order)) {
          updatedInventories[productId] = {
            ...inventory,
            sold: sold,
            order: order
          };
          hasChanges = true;
  
          // Save the calculated order to the database if it is not manually set
          if (!inventory.orderManuallySet) {
            const deliveryProductId = inventory.id;
            if (deliveryProductId) {
              api.patch(`/api/delivery-products/${deliveryProductId}/`, {
                sold: sold,
                order: order
              })
              .catch(error => {
                console.error('There was an error updating the product inventory!', error);
              });
            }
          }
        }
      });
  
      if (hasChanges) {
        setProductInventories(updatedInventories);
      }
    };
  
    calculateAndSaveInventory();
    console.log("Product Inventories: ", productInventories);
  }, [productInventories, isDataFetched]); // Ensure this effect runs whenever productInventories change
  
  
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setDeliveryDate(newDate);

    // Update the delivery date in the database
    api.patch(`/api/deliveries/${deliveryId}/`, { date: newDate })
      .catch(error => {
        console.error('There was an error updating the delivery date!', error);
      });
  };

  const saveAllInventories = useCallback(() => {
    const promises = Object.keys(productInventories).map(productId => {
      const inventory = productInventories[productId];
      const deliveryProductId = inventory.id;
  
      // Only save if there are changes
      if (deliveryProductId) {
        return api.patch(`/api/delivery-products/${deliveryProductId}/`, {
          previous_inventory: inventory.previous_inventory,
          current_inventory: inventory.current_inventory,
          outdated_inventory: inventory.outdated_inventory,
          eight_week_average: inventory.eight_week_average,
          quantity: inventory.quantity,
          sold: inventory.sold,
          order: inventory.order,
          order_manually_set: inventory.orderManuallySet
        })
        .catch(error => {
          console.error('There was an error updating the product inventory!', error);
        });
      }
  
      return Promise.resolve();
    });
    
    // Save glass bottle info
    const glassBottleData = {
      bottles_picked_up: bottlesPickedUp,
      bottles_delivered: totalBottlesDelivered,
      outdated_glass_bottles: outdatedGlassBottles
    };

    // Save the Delivery object including the glass bottle info
    const deliveryUpdate = api.patch(`/api/deliveries/${deliveryId}/`, glassBottleData)
      .catch(error => {
        console.error('There was an error updating the delivery!', error);
      });

      return Promise.all([...promises, deliveryUpdate]);
  }, [productInventories, bottlesPickedUp, totalBottlesDelivered, outdatedGlassBottles, deliveryId]);  

  const handleDone = () => {
    saveAllInventories().then(() => {
      navigate('/deliveries');
    });
  };  

  const handleDownloadPdf = () => {

    setIsGeneratingPdf(true);  // Disable the button and show the spinner

    saveAllInventories().then(() => {
      api.get(`/api/generate-pdf/${deliveryId}/`, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          // Open the PDF in a new tab
          const newWindow = window.open(url, '_blank');
          if (newWindow) {
              newWindow.focus();
          } else {
              setNotification('Pop-up blocked! Please allow pop-ups for this site.');
              setIsError(true);
          }

          setNotification('Pick List created successfully!');
          setIsError(false);
        })
        .catch(async (error) => {
          console.error('There was an error generating the PDF!', error);
          let errorMessage = 'Error generating PDF. Please try again.';
  
          if (error.response && error.response.data) {
            errorMessage = await parseBlobError(error.response.data);
          }
  
          setNotification(errorMessage);
          setIsError(true);
        })
        .finally(() => {
          setIsGeneratingPdf(false);
        });
    });
  };

  const handleShowDateModal = () => {
    setInvoiceDate(deliveryDate);  // Set invoice date to current delivery date
    setShowDateModal(true);
  };

  const handleCloseModal = () => {
    setShowDateModal(false);
  };

  const handleShowCreditMemoModal = () => {
    setCreditMemoDate(deliveryDate);  // Set default date to the delivery date
    setShowCreditMemoModal(true);  // Open the modal
  };
  
  const handleCloseCreditMemoModal = () => {
    setShowCreditMemoModal(false);  // Close the modal
  };  

  const handleCreateInvoice = () => {
    setIsGeneratingInvoice(true);

    saveAllInventories().then(() => {
      api.post(`/api/create-invoice/`, { deliveryId, invoiceDate }, { responseType: 'blob' })
        .then(response => {
          // Handle the case where the PDF is returned
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          const newTab = window.open(url, '_blank');  // Open PDF in a new tab
          if (newTab) {
            newTab.focus();  // Focus on the new tab
          } else {
            setNotification('Pop-up blocked! Please allow pop-ups for this site.');
            setIsError(true);
          }
          
          setNotification('Invoice created and opened in a new tab!');
          setIsError(false);
        })
        .catch(async (error) => {
          if (error.response?.status === 401) {
            sessionStorage.setItem('deliveryId', deliveryId);
            sessionStorage.setItem('invoiceDate', invoiceDate);
  
            const oauthUrl = error.response?.data?.oauth_url || 'https://books.sunrisedairytn.com/quickbooks';
            window.location.href = oauthUrl;
          } else {
            console.error('Error creating invoice:', error);
            let errorMessage = 'Error creating invoice. Please try again.';
  
            if (error.response && error.response.data) {
              errorMessage = await parseBlobError(error.response.data);
            }
  
            setNotification(errorMessage);
            setIsError(true);
          }
        })
        .finally(() => {
          setIsGeneratingInvoice(false);
          setShowDateModal(false);
        });
    });
  };

  const handleCreateCreditMemo = () => {
    setIsGeneratingInvoice(true);  // Reusing this loading state
  
    saveAllInventories().then(() => {
      api.post(`/api/create-creditmemo/`, { deliveryId, creditMemoDate }, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          const newTab = window.open(url, '_blank');  // Open PDF in a new tab
          if (newTab) {
            newTab.focus();  // Focus on the new tab
          } else {
            setNotification('Pop-up blocked! Please allow pop-ups for this site.');
            setIsError(true);
          }
  
          setNotification('Credit Memo created and opened in a new tab!');
          setIsError(false);
        })
        .catch(async (error) => {
          console.error('Error creating credit memo:', error);
          let errorMessage = 'Error creating credit memo. Please try again.';
  
          if (error.response && error.response.data) {
            errorMessage = await parseBlobError(error.response.data);
          }
  
          setNotification(errorMessage);
          setIsError(true);
        })
        .finally(() => {
          setIsGeneratingInvoice(false);
          setShowCreditMemoModal(false);  // Close the modal after action
        });
    });
  };
  
  const handleBottlesPickedUpChange = (e) => {
    const value = e.target.value;
    setBottlesPickedUp(value === '' ? null : parseFloat(value));  // Allow empty string
  };

  useEffect(() => {
    if (products.length > 0) {
      // Filter glass bottles and sum their 'order' or relevant field
      const glassBottlesDelivered = products
        .filter(product => product.glass_bottle)  // Filter products with glass_bottle=True
        .reduce((total, product) => total + (productInventories[product.id]?.order || 0), 0);

      // Calculate outdated glass bottles
      const outdatedGlassBottlesSum = products
        .filter(product => product.glass_bottle)  // Filter products with glass_bottle=True
        .reduce((total, product) => total + (productInventories[product.id]?.outdated_inventory || 0), 0);

      setTotalBottlesDelivered(glassBottlesDelivered);
      setOutdatedGlassBottles(outdatedGlassBottlesSum);
    }
  }, [products, productInventories]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Trigger the save function before unload
      saveAllInventories();
      event.preventDefault();
      event.returnValue = ''; // For most browsers
    };
  
    // Add event listeners
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleBeforeUnload);
  
    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleBeforeUnload);
      saveAllInventories(); // Save inventories when component unmounts
    };
  }, [productInventories, saveAllInventories]);  

  return (
    <form className="max-w-4xl mx-auto p-4">
      {isLoading ? (
      <div className="flex justify-center items-center">
        <div className="loader" role="status"></div>
        <p className="ml-2">Loading products...</p>
      </div>
    ) : (
      <>

      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <div className="md:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="store">
            Store
          </label>
          <select
            id="store"
            value={selectedStore}
            onChange={(e) => setSelectedStore(e.target.value)}
            className="block w-full p-2 sm:p-3 md:p-4 border border-gray-300 rounded"
            required
            disabled
          >
            <option value="">Select Store</option>
            {stores.map(store => (
              <option key={store.id} value={store.id}>{store.name}</option>
            ))}
          </select>
        </div>
        <div className="md:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
            Date
          </label>
          <input
            type="date"
            id="date"
            value={deliveryDate}
            onChange={handleDateChange}
            className="block w-full p-2 sm:p-3 md:p-4 border border-gray-300 rounded"
            required
          />
        </div> 
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        {/* Download Pick List Button */}
        <div className="md:col-span-1 flex items-end w-full">
          <button
            type="button"
            onClick={handleDownloadPdf}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded flex items-center justify-center"
            disabled={isGeneratingPdf}
          >
            <FontAwesomeIcon icon={faFileDownload} size="lg" className="mr-2" />
            {isGeneratingPdf ? (
              <div className="flex items-center">
                <div className="loader mr-2" role="status"></div>
                Generating PDF...
              </div>
            ) : (
              'Generate Pick List'
            )}
          </button>
        </div>

        {/* Create Invoice Button */}
        <div className="md:col-span-1 flex items-end w-full">
          <button
            type="button"
            onClick={handleShowDateModal}
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded flex items-center justify-center ml-4"
            disabled={isGeneratingInvoice}
          >
            <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" className="mr-2" />
            {isGeneratingInvoice ? (
              <div className="flex items-center">
                <div className="loader mr-2" role="status"></div>
                Creating Invoice...
              </div>
            ) : (
              'Create Invoice'
            )}
          </button>
        </div>

        {/* Create Credit Memo Button */}
        <div className="md:col-span-1 flex items-end w-full">
          <button
            type="button"
            onClick={handleShowCreditMemoModal}
            className="bg-green-lighter hover:bg-green-600 text-white font-bold py-3 px-6 rounded flex items-center justify-center ml-4"
            disabled={isGeneratingInvoice}
          >
            <FontAwesomeIcon icon={faReceipt} size="lg" className="mr-2" />
            {isGeneratingInvoice ? (
              <div className="flex items-center">
                <div className="loader mr-2" role="status"></div>
                Creating Credit Memo...
              </div>
            ) : (
              'Create Credit Memo'
            )}
          </button>
        </div>
      </div>

      {notification && (
          <div className={`mt-6 mb-4 p-6 rounded shadow-lg ${isError ? 'bg-red-500' : 'bg-green-500'} text-white`}>
              {notification}
          </div>
      )}

      <div><h3 className="text-xl font-bold mb-4">Products</h3></div>
      {hasGlassBottles && (
        <div className="p-2 mb-2 rounded-lg shadow-md" style={{ backgroundColor: '#90A4AE' }}>
          <h4 className="text-lg font-bold mb-2">Glass Bottles</h4>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
            <div className="md:col-start-1 lg:col-start-4">
              <label className="block text-gray-700 text-xs font-bold mb-1">Outdated Glass Btls</label>
              <input
                type="number"
                value={outdatedGlassBottles}
                disabled
                className="block w-full p-2 sm:p-3 md:p-4 border border-gray-300 rounded"
                placeholder="Enter number"
              />
            </div>
            <div className="md:col-start-2 lg:col-start-5">
              <label className="block text-gray-700 text-xs font-bold mb-1">Bottles Picked Up</label>
              <input
                type="number"
                value={bottlesPickedUp}
                onChange={handleBottlesPickedUpChange}
                className="block w-full p-2 sm:p-3 md:p-4 border border-gray-300 rounded"
                placeholder="Enter number"
              />
            </div>
            <div className="md:col-start-3 lg:col-start-6">
            <label className="block text-gray-700 text-xs font-bold mb-1">Bottles Delivered</label>
            <input
              type="number"
              value={totalBottlesDelivered} // Just use totalBottlesDelivered
              disabled
              className="block w-full p-2 sm:p-3 md:p-4 border border-gray-300 rounded"
              placeholder="Enter number"
            />
          </div>
          </div>
        </div>
      )}
      <div>
        
        {products.map(product => {

          const productId = product.id || `product-${Math.random()}`; // Fallback to a random key if ID is missing
          const backgroundColor = product.color || 'white';
          const productName = product.name || 'Unnamed Product';

          return (
            <div key={productId} className="p-2 mb-2 rounded-lg shadow-md" style={{ backgroundColor }}>
              <h4 className="text-lg font-bold mb-2">{productName}</h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                <div>
                  <label className="block text-gray-700 text-xs font-bold mb-1" htmlFor={`previous-inventory-${productId}`}>
                    Previous Inventory
                  </label>
                  <input
                    type="number"
                    id={`previous-inventory-${productId}`}
                    placeholder="Previous Inventory"
                    value={productInventories[product.id]?.previous_inventory ?? ''}
                    onChange={(e) => handleProductQuantityChange(product.id, 'previous_inventory', e.target.value === '' ? '' : parseFloat(e.target.value))}
                    className="block w-full p-2 sm:p-3 md:p-4 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-xs font-bold mb-1" htmlFor={`current-inventory-${productId}`}>
                    Current Inventory
                  </label>
                  <input
                    type="number"
                    id={`current-inventory-${productId}`}
                    placeholder="Current Inventory"
                    value={productInventories[product.id]?.current_inventory ?? ''}
                    onChange={(e) => handleProductQuantityChange(product.id, 'current_inventory', e.target.value === '' ? '' : parseFloat(e.target.value))}
                    className="block w-full p-2 sm:p-3 md:p-4 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-xs font-bold mb-1" htmlFor={`sold-${productId}`}>
                    Sold
                  </label>
                  <input
                    type="number"
                    id={`sold-${productId}`}
                    placeholder="Sold"
                    value={productInventories[product.id]?.sold || 0}
                    disabled
                    className="block w-full p-2 sm:p-3 md:p-4 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-xs font-bold mb-1" htmlFor={`outdated-inventory-${productId}`}>
                    Outdated Inventory
                  </label>
                  <input
                    type="number"
                    id={`outdated-inventory-${productId}`}
                    placeholder="Outdated Inventory"
                    value={productInventories[product.id]?.outdated_inventory ?? ''}
                    onChange={(e) => handleProductQuantityChange(product.id, 'outdated_inventory', e.target.value === '' ? '' : parseFloat(e.target.value))}
                    className="block w-full p-2 sm:p-3 md:p-4 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-xs font-bold mb-1" htmlFor={`eight-week-average-${productId}`}>
                    8 Week Average
                  </label>
                  <input
                    type="number"
                    id={`eight-week-average-${productId}`}
                    placeholder="8 Week Average"
                    value={productInventories[product.id]?.eight_week_average || 0}
                    disabled
                    className="block w-full p-2 sm:p-3 md:p-4 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-xs font-bold mb-1" htmlFor={`order-${productId}`}>
                    Order
                  </label>
                  <input
                    type="number"
                    id={`order-${productId}`}
                    placeholder="Order"
                    value={productInventories[product.id]?.order === null || productInventories[product.id]?.order === undefined ? '' : productInventories[product.id]?.order}
                    onChange={(e) => handleProductQuantityChange(product.id, 'order', parseFloat(e.target.value))}
                    className="block w-full p-2 sm:p-3 md:p-4 border border-gray-300 rounded"
                  />
                </div>
              </div>
            </div>
          );
        })}
            </div>
            
      <div className="md:col-span-3 flex justify-center w-full mt-6">
        <button
          type="button"
          onClick={handleDone}
          className="bg-blue-600 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
        >
          Done
        </button>
      </div>
      
      
      {previousDelivery && (
        <div className="mb-4 p-4 border border-gray-300 rounded bg-gray-100 mt-6">
          <h4 className="text-lg font-semibold text-gray-800">Previous Delivery Information</h4>
          <p className="text-sm text-gray-700 mt-2">
            <strong>Delivery ID:</strong> {previousDelivery.id ? previousDelivery.id : 'Not available'}
          </p>
          <p className="text-sm text-gray-700 mt-1">
            <strong>Delivery Date:</strong> {previousDelivery.date ? 
              new Date(previousDelivery.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }) : 'Not available'}
          </p>
          <p className="text-sm text-gray-700 mt-1">
            <strong>Created by:</strong> {previousDelivery.created_by || 'Unknown'}
          </p>
          <p className="text-sm text-gray-700 mt-1">
            <strong>Created at:</strong> {previousDelivery.created_at ? 
              new Date(previousDelivery.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              }) : 'Not available'}
          </p>
        </div>
      )}
      </>
      )}
      {/* Modal for Invoice Date */}
      <Modal
        isOpen={showDateModal}
        onRequestClose={handleCloseModal}
        contentLabel="Select Invoice Date"
        className="bg-white rounded p-4 max-w-md mx-auto mt-20 shadow-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-xl mb-4">Select Invoice Date</h2>
        <input
          type="date"
          value={invoiceDate}
          onChange={(e) => setInvoiceDate(e.target.value)}
          className="w-full border p-2 rounded mb-4"
        />
        <div className="flex justify-end space-x-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className={`px-4 py-2 rounded ${isGeneratingInvoice ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 text-white'}`}
            onClick={handleCreateInvoice}
            disabled={isGeneratingInvoice}
          >
            {isGeneratingInvoice ? (
              <>
                <div className="loader"></div>
                <span className="ml-2">Creating...</span>
              </>
            ) : 'Create QB Invoice'}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showCreditMemoModal}
        onRequestClose={handleCloseCreditMemoModal}
        contentLabel="Select Credit Memo Date"
        className="bg-white rounded p-4 max-w-md mx-auto mt-20 shadow-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-xl mb-4">Select Credit Memo Date</h2>
        <input
          type="date"
          value={creditMemoDate}
          onChange={(e) => setCreditMemoDate(e.target.value)}
          className="w-full border p-2 rounded mb-4"
        />
        <div className="flex justify-end space-x-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={handleCloseCreditMemoModal}
          >
            Cancel
          </button>
          <button
            className={`px-4 py-2 rounded ${isGeneratingInvoice ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 text-white'}`}
            onClick={handleCreateCreditMemo}
            disabled={isGeneratingInvoice}
          >
            {isGeneratingInvoice ? (
              <>
                <div className="loader"></div>
                <span className="ml-2">Creating...</span>
              </>
            ) : 'Create Credit Memo'}
          </button>
        </div>
      </Modal>
    </form>
  );
};

export default EditDelivery;
