export const parseBlobError = async (blob) => {
    try {
      const text = await blob.text();
      const data = JSON.parse(text);
      if (data.error) {
        return `Error: ${data.error}`;
      } else if (data.message) {
        return `Error: ${data.message}`;
      } else if (data.detail) {
        return `Error: ${data.detail}`;
      } else {
        return 'An unexpected error occurred. Please try again.';
      }
    } catch (e) {
      console.error('Failed to parse blob error:', e);
      return 'An unexpected error occurred. Please try again.';
    }
  };

  export default parseBlobError;